<template>
    <div class="list">
        <card-swipe class="slider" stack="right" :show-indicators="false" ref="myCardSwipe">
            <card-swipe-item v-for="review in reviews" :key="review.id">
                <div class="slide d-flex align-items-center">
                    <div v-if="review.photo" class="img img-wrapper d-flex align-items-center justify-content-center">
                        <img :src="require(`./assets/${review.photo}.png`)" alt="user photo">
                    </div>
                    <div class="wrapper">
                        <span class="name">{{ review.name }}</span>
                        <div class="rate d-flex align-items-center">
                            <div class="icon img-wrapper d-flex align-items-center justify-content-center" v-for="(num, index) in review.rate" :key="index">
                                <img src="./assets/star.svg" alt="star icon">
                            </div>
                        </div>
                        <p class="description text-overflow" :title="review.description">
                            {{ review.description }}
                        </p>
                    </div>
                </div>
            </card-swipe-item>
        </card-swipe>
    </div>
</template>

<script>
import { CardSwipe, CardSwipeItem } from '@eshengsky/vue-card-swipe';

export default {
    components: {
        CardSwipe,
        CardSwipeItem,
    },
    data() {
        return {
            reviews: [
                {
                    id: this.getRandomInt(),
                    name: 'Алексей',
                    rate: 5,
                    description: 'Мне нравится, что деньги получаешь быстро, никакой волокиты, никаких справок о доходах. Просто паспорт и снилс, а остальное делает за тебя система. Деньги приходят на привязанную карту, с нее же и погашаю потом.',
                    photo: 'Alex',
                },
                {
                    id: this.getRandomInt(),
                    name: 'Ирина',
                    rate: 5,
                    description: 'Решила обратиться в сервис онлайн займов "Kviza", так как не хватало денег на новый телефон, а брать абы что не хотелось, так как понимала, что в последствии смогу отдать с будущей зп. Заявку вообще ночью оформила у них на официальном сайте и не ожидала, что ответ придёт через несколько минут. Потом меня никто не тревожил, а когда вернула займ, дали скидки на следующие займы. Теперь я счастливая обладательница нового телефона)',
                    photo: 'Irina',
                },
                {
                    id: this.getRandomInt(),
                    name: 'Анна',
                    rate: 5,
                    description: 'Часто обращаюсь в разные МФО когда нужны деньги, к сожалению кредит или карту получить не могу, а в займах еще дают деньги. В общем есть с чем сравнивать, поэтому была приятно удивлена работой Квиза когда платеж не прошел. Позвонили, уточнили и подсказали как сделать все правильно в этой ситуации, так что историю свою еще хуже не попортила. Спасибо за такое отношение!',
                    photo: 'Anna',
                },
                {
                    id: this.getRandomInt(),
                    name: 'Сергей',
                    rate: 5,
                    description: 'Иногда складываются такие ситауции, что деньги взять негде, поэтому приходится обращаться за заемными средствами. Я приходил несколько раз и мне всегда одобряли нужню сумму. Ваша компания помогла мне выйти из сложной финансовой ситуации. Спасибо!',
                    photo: 'Sergey',
                },
                {
                    id: this.getRandomInt(),
                    name: 'Ольга',
                    rate: 5,
                    description: 'Большое спасибо за существование вашей компании. Квиза - настоящая палочка-выручалочка в моей жизни в плане финансов. Все у вас хорошо и замечательно! Мне все в вас нравится! Процветания вам и побольше хороших клиентов. Так держать!',
                    photo: 'Olga',
                },
            ],
        };
    },
    methods: {
        getRandomInt() {
            return Math.floor(Math.random() * 100000000);
        },
    },
}
</script>

<style lang="scss" scoped>

.card-swipe-item {
  cursor: grab;
}

.card-swipe__wrapper.right .card-swipe-item:nth-last-child(2) .slide, .card-swipe__wrapper.right .draging .card-swipe-item:nth-last-child(3) .slide {
  transform: translate(9px, 0) scaleY(0.98);
  background: linear-gradient(0deg, #C3D0EE, #C3D0EE), #FAFAFA;
  opacity: 1;
}

.card-swipe__wrapper.right .card-swipe-item:nth-last-child(3) .slide, .card-swipe__wrapper.right .draging .card-swipe-item:nth-last-child(4) .slide {
  transform: translate(15px, 0) scaleY(0.94);
  opacity: 1;
  background: linear-gradient(0deg, #C3D0EE, #C3D0EE), #FAFAFA;
}

.slider {
  width: 602px;
  margin: 0 auto;
}

.list {
  position: relative;
  width: 100%;
}
.card-swipe-item .slide {
  padding: 36px 24px;
  height: 274px;
}

.card-swipe-item .slide {
  background: #FEFEFE;
}

.img {
  max-width: 201px;
  height: 201px;
  border-radius: 10px;
  margin-right: 27px;
  flex-shrink: 0;
}
.name, .description {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.07em;
}
.name {
  display: inline-block;
  font-weight: 600;
  margin-bottom: 12px;
}
.description {
  line-height: 24px;
  max-width: 294px;
  -webkit-line-clamp: 7;
}
.rate {
  margin-bottom: 12px;
  .icon {
    max-width: 18px;
    height: 18px;
  }
  .icon + .icon {
    margin-left: 6px;
  }
}
@media(max-width: $mobile) {
  .slider {
    width: 320px;
    height: 180px;
    @media(max-width: 374px) {
      width: 290px;
    }
  }
  .card-swipe-item {
    max-height: 180px;

    .slide {
      height: 180px;
      padding: 20px 14px 20px 20px;
      align-items: flex-start !important;
    }
  }
  .img {
    max-width: 100px;
    height: 100px;
    margin-right: 22px;
  }
  .name {
    margin-bottom: 8px;
  }
  .description {
    font-size: 12px;
    line-height: 18px;
    max-width: 100%;
    -webkit-line-clamp: 5;
  }
  .rate {
    margin-bottom: 8px;
    .icon {
      max-width: 12px;
      height: 12px;
    }
  }
}
@media(max-width: 374px) {
  .card-swipe__wrapper.right .card-swipe-item:nth-last-child(2) .slide, .card-swipe__wrapper.right .draging .card-swipe-item:nth-last-child(3) .slide {
    transform: translate(3px, 0) scaleY(0.98);
  }

  .card-swipe__wrapper.right .card-swipe-item:nth-last-child(3) .slide, .card-swipe__wrapper.right .draging .card-swipe-item:nth-last-child(4) .slide {
    transform: translate(12px, 0) scaleY(0.94);
  }
}
</style>